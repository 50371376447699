import React, { useContext } from 'react';
import './styles.scss';
import { addOverlay, OverlayEnum, SspProviderContext } from '../../did-utility';
import SSPContentVO from 'sspcontentext/src/model/SSPContentVO';
import Disclaimers from '../../did-utility/src/Components/Disclaimers';
import {
	ConsumptionElementType,
	consumptionList,
	ConsumptionListType,
	disclaimerTagsInConsumptionLists,
	getConsumptionValue,
} from '../../did-utility/src/Functions/consumptionLists';
import i18next from 'i18next';
import { engineTypes } from '../../did-utility/src/Components/Label/dataLabel';
import { getSetup } from '../../did-utility/src/Functions/getSetup';

export default function TechData(): JSX.Element {
	const store = useContext(SspProviderContext);
	const setup = getSetup();
	const contentModel = store.state.content
		? store.state.content.asModel
		: new SSPContentVO('').asModel;
	const vehicleDataModel = contentModel.ref('vehicle_data').asModel;
	const engine = contentModel.ref('engine').asModel;
	const engineType = engineTypes.includes(
		engine.str('engine_type', 'Default')
	)
		? (engine.str('engine_type', 'Default') as keyof ConsumptionListType)
		: ('Default' as keyof ConsumptionListType);
	const techEntries = contentModel.ref('engine').asModel.reflist('entries');
	const tyreLabels = contentModel
		.ref('vehicle_data')
		.asModel.reflist('tyre_labels');
	//nedc deprecated
	// const hasNEDCData = vehicleDataModel
	// 	.keys()
	// 	.some(
	// 		key =>
	// 			key.includes('nedc') &&
	// 			!(
	// 				(key.includes('efficiency') &&
	// 					vehicleDataModel.str('nedc_energy_efficiency') ===
	// 						'none') ||
	// 				vehicleDataModel.str('nedc_energy_efficiency') === '-'
	// 			)
	// 	);
	// const nedcKeys = vehicleDataModel
	// 	.keys()
	// 	.filter(key => key.includes('nedc'));
	// const hasOneNEDCData = nedcKeys.length === 1;
	const hasWLTPData =
		vehicleDataModel.keys().some(key => key.includes('wltp')) ||
		(setup.market.name === 'MEX' &&
			vehicleDataModel
				.keys()
				.some(
					key =>
						key.includes('City') ||
						key.includes('Highway') ||
						key.includes('Observed')
				));
	let tyreString = '';

	tyreLabels.forEach((label, index) => {
		tyreString +=
			label.asModel.str('type') +
			' ' +
			label.asModel.str('tyreDesignation') +
			(index === tyreLabels.length - 1 ? '' : ', ');
	});

	const engineDisclaimers = engine.arr('disclaimers') as Array<{
		Key: string;
		Value: string;
	}>;
	const disclaimerTags: Array<string> = [
		...disclaimerTagsInConsumptionLists(engineType),
	];
	techEntries.forEach(entry => {
		(
			entry.asModel.arr('entries') as Array<{
				Key: string;
				Value: string;
			}>
		).forEach(e => {
			const tag = e.Key.substring(
				e.Key.indexOf('#') + 1,
				e.Key.lastIndexOf('#')
			);
			const index = engineDisclaimers.findIndex(dis => dis.Key === tag);
			if (tag.length > 0 && index !== -1) {
				disclaimerTags.push(tag);
			}
		});
	});

	const getConsumption = (c: ConsumptionElementType) => {
		const disclaimerNumber =
			c.disclaimer && disclaimerTags.includes(c.disclaimer)
				? engineDisclaimers.findIndex(d => d.Key === c.disclaimer)
				: -1;
		if (c.hideIfEmpty && !vehicleDataModel.has(c.key)) {
			return <></>;
		}

		return (
			<div
				className='table-element tech-data'
				key={`tech-data-consumption-element-${c.key}`}
			>
				<h6 className='start'>
					{i18next.t(c.headline)}
					{disclaimerNumber !== -1 && (
						<sup>{disclaimerNumber + 1}</sup>
					)}
				</h6>
				<p className='short middle with-end'>
					{getConsumptionValue(c, vehicleDataModel)}
				</p>
			</div>
		);
	};

	return (
		<>
			{tyreString && (
				<>
					<div className='table-element headline tech-data'>
						<h6 className='bold'>
							{i18next.t('TireLabelInformation')}
						</h6>
					</div>
					<div
						className='table-element tech-data'
						onClick={() => addOverlay(store, OverlayEnum.Tire)}
					>
						<h6 className='start'>
							{i18next.t('TireLabelMainTires')}
						</h6>
						<p className='short middle with-end ellipsis'>
							{tyreString}
						</p>
						<div className='info-icon'>
							<svg>
								<use xlinkHref='#info-icon' />
							</svg>
						</div>
					</div>
				</>
			)}
			{hasWLTPData && (
				<>
					<div className='table-element headline tech-data'>
						<h6 className='bold'>
							{i18next.t('WltpValuesTableHeadline')}
						</h6>
					</div>
					{consumptionList.wltp &&
						consumptionList.wltp[engineType]?.map((item, index) => (
							<React.Fragment key={item.key}>
								{getConsumption(item)}
							</React.Fragment>
						))}
				</>
			)}
			{setup.market && setup.market.nedc && (
				<>
					<div className='table-element headline tech-data'>
						<h6 className='bold'>
							{i18next.t('NedcValuesTableHeadline')}
						</h6>
					</div>
					{/*{!hasNEDCData ? (*/}
					{/*	<div className='table-element tech-data'>*/}
					{/*		<p className='p2 missing'>*/}
					{/*			{i18next.t('MissingNedcValuesDisclaimer')}*/}
					{/*		</p>*/}
					{/*		<p className='missing' />*/}
					{/*	</div>*/}
					{/*) : hasOneNEDCData ? (*/}
					{/*	<>*/}
					{/*		{consumptionList.nedc &&*/}
					{/*			consumptionList.nedc[engineType]?.map(*/}
					{/*				getConsumption*/}
					{/*			)}*/}
					{/*		<div>*/}
					{/*			<p className='p2 missing'>*/}
					{/*				{i18next.t('MissingNedcValuesDisclaimer')}*/}
					{/*			</p>*/}
					{/*		</div>*/}
					{/*	</>*/}
					{/*) : (*/}
					{/*	<>*/}
					{/*		{consumptionList.nedc &&*/}
					{/*			consumptionList.nedc[engineType]?.map(*/}
					{/*				getConsumption*/}
					{/*			)}*/}
					{/*	</>*/}
					{/*)}*/}
				</>
			)}
			{techEntries.map((entry, index) => {
				const languages = Object.keys(
					i18next.options.resources as Object
				);
				let entryList;
				if (
					languages.length > 1 &&
					entry.asModel.target.entries[i18next.language]
				) {
					entryList = entry.asModel.target.entries[
						i18next.language
					] as Array<{
						Key: string;
						Value: string;
					}>;
				} else {
					entryList = entry.asModel.arr('entries') as Array<{
						Key: string;
						Value: string;
					}>;
				}
				let entryTitle;
				if (
					languages.length > 1 &&
					entry.asModel.target.name[i18next.language]
				) {
					entryTitle = entry.asModel.target.name[i18next.language];
				} else {
					entryTitle = entry.asModel.str('name');
				}
				return (
					<React.Fragment key={`entry-${index}`}>
						<div
							className={`table-element headline tech-data ${
								entryList.length < 1 ||
								(entryList.length === 1 &&
									entryList[0].Key === '')
									? 'empty'
									: ''
							}`}
						>
							<h6 className='bold'>{entryTitle}</h6>
						</div>
						{entryList.map((e, index) => {
							let key = (
								<h6
									className={`start ${
										e.Key === '' ? 'empty' : ''
									}`}
								>
									{e.Key}
								</h6>
							);
							engineDisclaimers.forEach((disclaimer, index) => {
								if (e.Key.includes(disclaimer.Key)) {
									key = (
										<h6 className='start'>
											{e.Key.replace(
												'#' + disclaimer.Key + '#',
												''
											)}
											<sup>{index + 1}</sup>
										</h6>
									);
								}
							});
							return (
								<div
									className='table-element tech-data'
									key={`entry-e-${index}`}
								>
									{key}
									<p className='short'>{e.Value}</p>
								</div>
							);
						})}
					</React.Fragment>
				);
			})}
			{disclaimerTags.length > 0 && engineDisclaimers.length > 0 && (
				<Disclaimers
					tagsUsed={disclaimerTags}
					complete={engineDisclaimers}
				/>
			)}
		</>
	);
}
