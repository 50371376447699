export default function replaceConsoleFunctions(appName: string) {
    function postConsoleOutput(level: string, msg: string) {
        fetch(`https://fluentd.b12-devprojects.de/app.${appName}`, {
            method: 'POST',
            body: JSON.stringify({
                level: level.toUpperCase(),
                message: msg,
            }),
        });
    }

    const oldLog = window.console.log;
    window.console.log = function (msg) {
        postConsoleOutput('log', msg);
        oldLog(msg);
    };

    const oldError = window.console.error;
    window.console.error = function (msg) {
        if (!msg.includes('two children with the same key'))
            postConsoleOutput('error', msg);
        oldError(msg);
    };

    const oldWarn = window.console.warn;
    window.console.warn = function (msg) {
        postConsoleOutput('warn', msg);
        oldWarn(msg);
    };

    const oldInfo = window.console.info;
    window.console.info = function (msg) {
        postConsoleOutput('info', msg);
        oldInfo(msg);
    };
}
