import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.scss';
import App from './App';
import replaceConsoleFunctions from './did-utility/src/Functions/replaceConsoleFunctions';
import { registerSW } from './did-utility/src/Functions/serviceWorkerRegistration';
import { getSetup } from './did-utility/src/Functions/getSetup';

const params = new URLSearchParams(window.location.search);

if (
	!window.location.href.includes('localhost') &&
	params.get('log') === 'true'
) {
	replaceConsoleFunctions('did-audi');
}

const root = ReactDOM.createRoot(
	document.getElementById('root') as HTMLElement
);

registerSW(getSetup()?.password || '', 'audi', [
	'/audi/index.html',
	'/audi/src/assets/fonts/AudiType-ExtendedNormal_4.03.ttf',
	'/audi/src/assets/fonts/AudiType-ExtendedBold_4.03.ttf',
	'/audi/src/assets/fonts/AudiType-WideBold_4.03.ttf',
]);

if (params.get('iframe') === 'true') {
	let url = new URL(window.location.href);
	url.searchParams.delete('iframe');
	root.render(<iframe className='app' title='DID' src={url.toString()} />);
} else {
	root.render(
		<React.StrictMode>
			<App />
		</React.StrictMode>
	);
}
