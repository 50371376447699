import React, { useContext, useEffect, useState } from 'react';
import './accessories.scss';
import {
	addOverlay,
	filterForCategories,
	KeyboardWrapper,
	prepareList,
	searchResults,
	SspProviderContext,
} from '../../../did-utility';
import SSPContentVO from 'sspcontentext/src/model/SSPContentVO';
import { OverlayContentProps } from '../interfaces';
import HeightLimitCarousel from '../../../did-utility/src/Components/Carousel/HeightLimit';
import clickLinks from '../../../did-utility/src/Functions/clickLinks';
import { checkHtml } from '../../../did-utility/src/Functions/checkHTML';
import { TeaserImage } from '../../../did-utility/src/Components/TeaserImage';
import { showInfoIcon } from '../../../did-utility/src/Functions/showInfoIcon';
import i18next from 'i18next';
import dispatchLogEvent from '../../../did-utility/src/ActivityLogger';

export default function Accessories(props: OverlayContentProps): JSX.Element {
	const store = useContext(SspProviderContext);
	const contentModel = store.state.content
		? store.state.content.asModel
		: new SSPContentVO('').asModel;
	const salesGroup = contentModel.ref('salesgroupref');
	const accessoriesList = salesGroup.asModel.reflist('accessories');
	const categories: Array<string> = filterForCategories(
		accessoriesList,
		false
	).sort();

	const [navElementIndex, setNavElementIndex] = useState<number>(0);
	const [searching, setSearching] = useState<boolean>(false);
	const [searchString, setSearchString] = useState<string>('');
	const [showKeyboard, setShowKeyboard] = useState<boolean>(false);

	useEffect(() => {
		const navBar = document.getElementById('equip-nav-bar');
		if (navBar && categories.length > 1) {
			const element = navBar.getElementsByClassName(
				'nav-bar-element active'
			)[0] as HTMLElement;
			const scrollBy = element.offsetLeft - navBar.offsetLeft;
			navBar.scrollTo({ left: scrollBy });
		}
	}, [navElementIndex]);

	useEffect(() => {
		if (!showKeyboard && searchString.length > 0) {
			dispatchLogEvent({
				ActionName: 'Search',
				ElementContentType: 'Search',
				ElementName: searchString,
				ElementId: 'Search',
				ElementType: 'Accessories',
			});
		}
	}, [showKeyboard]);

	const input = (
		<div
			className={`input-wrapper${searching ? '' : ' width-74'}`}
			onClick={e => {
				e.stopPropagation();
				setSearching(true);
				setShowKeyboard(true);
			}}
		>
			<svg className='icon-24'>
				<use xlinkHref='#search' />
			</svg>
			<input
				value={searchString}
				inputMode='none'
				autoFocus={searching}
				onChange={e => setSearchString(e.target.value)}
				placeholder={i18next.t('ProductSearch')}
			/>
			{searching && (
				<svg
					className='icon-24'
					onClick={e => {
						e.stopPropagation();
						setSearching(false);
						setSearchString('');
						setShowKeyboard(false);
					}}
				>
					<use xlinkHref='#cancel' />
				</svg>
			)}
		</div>
	);

	const listElement = (item: SSPContentVO, prev?: string) => {
		const info = showInfoIcon(item);
		return (
			<>
				{prev &&
					(item.asModel.str('equipcategory') !== prev ||
						prev === '') && (
						<h4 className='light'>
							{item.asModel.str('equipcategory')}
						</h4>
					)}
				<div
					className='list-element'
					key={`accessory-${item.uid}`}
					onClick={() => {
						if (info) addOverlay(store, item);
					}}
				>
					<div className='text'>
						<div className='truncate'>
							<p className='short bold'>
								{item.asModel.str('layertitle')}
							</p>
							<p
								className='short'
								onClick={e => clickLinks(e, store)}
								dangerouslySetInnerHTML={checkHtml(
									'lorem ipsum sfsldjflö sdfkjsdfj sdfljköas  sdlfj sdfljlsd fkj sdfjlsdjflsdjfljd sfsldjflö sdfkjsdfj sdfljköas  sdlfj sdfljlsd fkj sdfjlsdjflsdjfljd sfsldjflö sdfkjsdfj sdfljköas  sdlfj sdfljlsd fkj sdfjlsdjflsdjfljd sfsldjflö sdfkjsdfj sdfljköas  sdlfj sdfljlsd fkj sdfjlsdjflsdjfljd sfsldjflö sdfkjsdfj sdfljköas  sdlfj sdfljlsd fkj sdfjlsdjflsdjfljd sfsldjflö sdfkjsdfj sdfljköas  sdlfj sdfljlsd fkj sdfjlsdjflsdjfljdsdfljlsd fkj sdfjlsdjflsdjfljd sfsldjflö sdfkjsdfj sdfljköas  sdlfj sdfljlsd fkj sdfjlsdjflsdjfljdsdfljlsd fkj sdfjlsdjflsdjfljd sfsldjflö sdfkjsdfj sdfljköas  sdlfj sdfljlsd fkj sdfjlsdjflsdjfljd ',
									i18next.t('ClickMe')
								)}
							/>
						</div>
					</div>
					<div className='teaser-image-container'>
						<TeaserImage
							equipItem={item}
							iconVideo={'#system-play'}
							altContent={
								info ? (
									<svg className='icon-48'>
										<use xlinkHref='#system-info' />
									</svg>
								) : (
									<></>
								)
							}
						/>
					</div>
				</div>
			</>
		);
	};

	const searchResultsArray = searching
		? searchResults(searchString, accessoriesList)
		: [];

	return (
		<div className='accessories' onClick={() => setShowKeyboard(false)}>
			{searching ? (
				<>
					<div className='sticky-top'>
						{props.closeBtn()}
						<h1>{i18next.t('AccessoriesSearch')}</h1>
						{input}
					</div>
					<div className='search-results'>
						{searchResultsArray.map((item, index) =>
							listElement(
								item,
								index > 0
									? searchResultsArray[index - 1].asModel.str(
											'equipcategory'
									  )
									: ''
							)
						)}
					</div>
				</>
			) : (
				<>
					<div className='sticky-top'>
						{props.closeBtn()}
						<h1>{i18next.t('AccessoriesHeading')}</h1>
						<p className='width-74 long'>
							{i18next.t('AccessoriesSubheading')}
						</p>
						{input}
						<div className='nav-bar' id='equip-nav-bar'>
							{categories.map((category, index) => (
								<h5
									className={`normal nav-bar-element${
										navElementIndex === index
											? ' active'
											: ''
									}`}
									onClick={() => setNavElementIndex(index)}
									key={`nav-bar-element-colors-${index}`}
								>
									{category}
								</h5>
							))}
						</div>
					</div>
					<div className='carousel-container'>
						<HeightLimitCarousel
							currentIndex={navElementIndex}
							setCurrentIndex={setNavElementIndex}
							margin={58} // side-padding
							slidingDisabled={categories.length === 1}
							keyName={'accessories'}
						>
							{categories.map((c, index) => (
								<>
									{prepareList(accessoriesList, c, false).map(
										item => listElement(item)
									)}
								</>
							))}
						</HeightLimitCarousel>
					</div>
				</>
			)}
			<div
				className={`keyboard-container${
					searching && showKeyboard ? ' show' : ''
				}`}
				onClick={e => e.stopPropagation()}
			>
				<KeyboardWrapper
					onChange={i => setSearchString(i)}
					onEnter={() => setShowKeyboard(false)}
					enterString={i18next.t('SearchEnter')}
				/>
			</div>
		</div>
	);
}
