import React, { useContext, useEffect, useState } from 'react';
import './common.scss';
import { addOverlay, SspProviderContext } from '../../../did-utility';
import { CommonProps } from '../interfaces';
import Slider from '../../../did-utility/src/Components/Slider';
import { checkHtml } from '../../../did-utility/src/Functions/checkHTML';
import { showInfoIcon } from '../../../did-utility/src/Functions/showInfoIcon';
import VideoPlayerAudi from '../../VideoPlayerAudi';
import clickLinks from '../../../did-utility/src/Functions/clickLinks';
import i18next from 'i18next';
import { logSlideChange } from '../../../did-utility/src/ActivityLogger/util';
import { marked } from 'marked';
import { __MARKED_OPTIONS } from '../../Constants';

export default function Common(props: CommonProps): JSX.Element {
	const store = useContext(SspProviderContext);
	const [currentSlideIndex, setCurrentSlideIndex] = useState<number>(0);
	const equipItem = props.overlay.asModel;
	const included = equipItem
		.reflist('packageItems')
		.filter(item => item.asModel.bool('marketable', true));
	const assets = equipItem.reflist('assets');
	const videoIndex = assets.findIndex(a => a.contentType === 'assetVideo');
	let tire: boolean;
	tire =
		equipItem.target.family === 'REI' ||
		equipItem.target.family === 'ZBR' ||
		equipItem.target.family === 'RAD';

	useEffect(() => {
		if (assets.length >= 2) {
			logSlideChange(assets[currentSlideIndex], currentSlideIndex);
		}
	}, [currentSlideIndex]);

	return (
		<div className='common' key={`common-${props.overlay.uid}`}>
			<div
				className={`sticky-top ${
					equipItem.str('description', '').length == 0 &&
					equipItem.str('benefits', '').length == 0 &&
					equipItem.str('details', '').length == 0 &&
					equipItem.str('remarks', '').length == 0 &&
					included.length == 0
						? 'no-padding'
						: equipItem.str('description', '').length > 0
						? 'padding-24'
						: ''
				}`}
			>
				{equipItem.has('assets') && (
					<Slider
						currentIndex={currentSlideIndex}
						setCurrentIndex={setCurrentSlideIndex}
						btnContent={
							<svg className='icon-48'>
								<use xlinkHref='#forward' />
							</svg>
						}
					>
						{assets.map((asset, index) => (
							<div className='slide' key={`slide-${index}`}>
								{asset.contentType !== 'assetVideo' ? (
									tire ? (
										<div className='tire'>
											<img
												src={asset.asAsset.url}
												alt={asset.asModel.str('name')}
											/>
										</div>
									) : (
										<img
											src={asset.asAsset.url}
											alt={asset.asModel.str('name')}
										/>
									)
								) : (
									<VideoPlayerAudi
										video={asset}
										visible={
											currentSlideIndex === videoIndex
										}
									/>
								)}
							</div>
						))}
					</Slider>
				)}

				{props.closeBtn()}

				{equipItem.str('layertitle', '').length > 0 && (
					<div className='headline-area side-padding-overlay'>
						{equipItem.str('equipcategory', '').length > 0 && (
							<p className='long screen'>
								{(equipItem.target.equipcategory &&
									equipItem.target.equipcategory[
										i18next.language
									]) ||
									equipItem.str('equipcategory')}
							</p>
						)}
						<h3>
							{(equipItem.target.layertitle &&
								equipItem.target.layertitle[
									i18next.language
								]) ||
								equipItem.str('layertitle')}
						</h3>
					</div>
				)}
			</div>

			<div className='side-padding-overlay'>
				{equipItem.str('description', '').length > 0 && (
					<div className='description bullets'>
						<p
							onClick={e => clickLinks(e, store)}
							dangerouslySetInnerHTML={checkHtml(
								marked.parse(
									(equipItem.target.description &&
										equipItem.target.description[
											i18next.language
										]) ||
										equipItem.str('description'),
									__MARKED_OPTIONS
								) as string,
								i18next.t('ClickMe')
							)}
						/>
					</div>
				)}
				{equipItem.str('benefits', '').length > 0 &&
					equipItem.str('benefits').length > 0 && (
						<>
							<p className='bold'>
								{i18next.t('EquipmentPartBenefits')}:
							</p>
							<div
								className='bullets'
								onClick={e => clickLinks(e, store)}
								dangerouslySetInnerHTML={checkHtml(
									marked.parse(
										(equipItem.target.benefits &&
											equipItem.target.benefits[
												i18next.language
											]) ||
											equipItem.str('benefits'),
										__MARKED_OPTIONS
									) as string,
									i18next.t('ClickMe')
								)}
							/>
						</>
					)}
				{equipItem.str('details', '').length > 0 && (
					<div className='bullets'>
						<p
							onClick={e => clickLinks(e, store)}
							dangerouslySetInnerHTML={checkHtml(
								marked.parse(
									(equipItem.target.details &&
										equipItem.target.details[
											i18next.language
										]) ||
										equipItem.str('details'),
									__MARKED_OPTIONS
								) as string,
								i18next.t('ClickMe')
							)}
						/>
					</div>
				)}

				{included.length > 0 && (
					<div className='included-list'>
						<h5 className='bold'>
							{i18next.t('EquipmentPackageContainedItems')}
						</h5>
						{included.map((inclItem, index) => {
							const showIcon = showInfoIcon(inclItem);
							return (
								<div
									className='included-list-element'
									key={`included-list-element-${index}`}
									onClick={() => {
										if (showIcon) {
											addOverlay(store, inclItem);
										}
									}}
								>
									<p className='long'>
										{(inclItem.asModel.target.layertitle &&
											inclItem.asModel.target.layertitle[
												i18next.language
											]) ||
											inclItem.asModel.str('layertitle')}
									</p>
									{showIcon && (
										<svg className='icon-24'>
											<use xlinkHref='#system-info' />
										</svg>
									)}
								</div>
							);
						})}
					</div>
				)}

				{equipItem.str('remarks', '').length > 0 && (
					<p
						className='long'
						onClick={e => clickLinks(e, store)}
						dangerouslySetInnerHTML={checkHtml(
							marked.parse(
								(equipItem.target.remarks &&
									equipItem.target.remarks[
										i18next.language
									]) ||
									equipItem.str('remarks'),
								__MARKED_OPTIONS
							) as string,
							i18next.t('ClickMe')
						)}
					/>
				)}
			</div>
			<div
				className={`padding-bottom${
					!equipItem.has('assets') ? '-no-image' : ''
				}`}
			/>
			<div className='scrollable-bottom-padding' />
		</div>
	);
}
