import React, { useContext, useEffect, useState } from 'react';
import './wheelsandpaint.scss';
import { OverlayEnum, SspProviderContext } from '../../../did-utility';
import { WheelsAndPaintProps } from '../interfaces';
import SSPContentVO from 'sspcontentext/src/model/SSPContentVO';
import HeightLimitCarousel from '../../../did-utility/src/Components/Carousel/HeightLimit';
import i18next from 'i18next';
import NotifyScrollable from '../../../did-utility/src/Components/NotifyScrollable';
import { logColorChange } from '../../../did-utility/src/ActivityLogger/util';
import getWheelAndColorTiles from '../../../did-utility/src/Functions/getWheelAndColorTiles';

export default function Colors(props: WheelsAndPaintProps): JSX.Element {
	const store = useContext(SspProviderContext);
	const contentModel = store.state.content
		? store.state.content.asModel
		: new SSPContentVO('').asModel;
	const salesGroupModel = contentModel.ref('salesgroupref').asModel;

	let headline;
	let subheadline;
	let categories: Array<{
		name: string;
		list: Array<SSPContentVO>;
		header?: string;
		subHeader?: string;
		usedUids?: Array<string>;
	}> = [];
	let grid = 4;

	const [navElementIndex, setNavElementIndex] = useState<number>(0);
	useEffect(() => logColorChange(navElementIndex), [navElementIndex]);

	const prString = String(contentModel.ref('prString').uid);
	const usedUidsWheels = salesGroupModel
		.reflist('completeWheels')
		.filter(wheel => prString.includes(wheel.asModel.str('number')))
		.map(wheel => wheel.uid);

	let intColors = getWheelAndColorTiles('intColors');
	let extColors = getWheelAndColorTiles('extColors');

	let summerWheels = getWheelAndColorTiles('completeWheels', 'RAD');
	let winterWheels = getWheelAndColorTiles('completeWheels', 'ZBR');

	if (summerWheels && !summerWheels[0]?.asModel.has('assets')) {
		summerWheels.reverse();
	}
	if (winterWheels && !winterWheels[0]?.asModel.has('assets')) {
		winterWheels.reverse();
	}

	if (props.overlay === OverlayEnum.Colors) {
		headline = 'ExtIntColors';
		subheadline = 'ExtIntColorsSubheadline';
		categories = [
			{
				name: 'ExtColors',
				list: extColors,
				subHeader: 'equipcategory',
				usedUids: [contentModel.ref('color_out_image').uid],
			},
			{
				name: 'IntColors',
				list: intColors,
				subHeader: 'equipcategory',
				usedUids: [contentModel.ref('color_in_image').uid],
			},
		];
	}

	if (props.overlay === OverlayEnum.Rims) {
		headline = 'CompleteWheels';
		categories = [
			{
				name: '',
				list:
					(summerWheels && summerWheels[0]?.asModel.has('assets')) ||
					(summerWheels &&
						!summerWheels[0]?.asModel.has('assets') &&
						winterWheels &&
						!winterWheels[0]?.asModel.has('assets'))
						? [...summerWheels, ...winterWheels]
						: [...winterWheels, ...summerWheels],
				header: 'family',
				usedUids: usedUidsWheels,
			},
		];
		grid = 3;
	}

	return (
		<NotifyScrollable>
			<div className='wheels-and-paint'>
				<div className='sticky-top'>
					{props.closeBtn()}
					<h1>{i18next.t(headline || '')}</h1>
					{subheadline && (
						<p className='p1 long'>{i18next.t(subheadline)}</p>
					)}
					{props.overlay === OverlayEnum.Colors &&
					categories.length > 1 ? (
						<div className='nav-bar'>
							{categories.map((category, index) => (
								<h5
									className={`normal nav-bar-element${
										navElementIndex === index
											? ' active'
											: ''
									}`}
									onClick={() => setNavElementIndex(index)}
									key={`nav-bar-element-colors-${index}`}
								>
									{i18next.t(category.name)}
								</h5>
							))}
						</div>
					) : (
						<></>
					)}
				</div>
				<div className={`carousel-container grid-${grid}`}>
					<HeightLimitCarousel
						currentIndex={navElementIndex}
						setCurrentIndex={setNavElementIndex}
						margin={58} // side-padding
						slidingDisabled={categories.length === 1}
						keyName={`wheels-and-paint-${props.overlay}-category`}
					>
						{categories.map(category => (
							<>
								{category.list.map((feature, index) => (
									<>
										{category.header &&
											(index === 0 ||
												feature.asModel.str(
													category.header
												) !==
													category.list[
														index - 1
													].asModel.str(
														category.header
													)) && (
												<h4 className='header'>
													{feature.asModel.str(
														category.header
													) === 'RAD' &&
														i18next.t(
															'ExtIntColorsSummerCompleteWheelsRAD'
														)}
													{feature.asModel.str(
														category.header
													) === 'ZBR' &&
														i18next.t(
															'ExtIntColorsWinterCompleteWheelsZBR'
														)}
												</h4>
											)}
										{category.subHeader &&
											(index === 0 ||
												feature.asModel.str(
													'equipcategory'
												) !==
													category.list[
														index - 1
													].asModel.str(
														'equipcategory'
													)) && (
												<h5 className='sub-header'>
													{feature.asModel.str(
														'equipcategory'
													)}
												</h5>
											)}
										<div
											className={`${
												!feature.asModel.has('assets')
													? 'no-img'
													: 'with-img'
											}`}
										>
											<div className='img'>
												{feature.asModel.has(
													'assets'
												) && (
													<img
														src={
															feature.asModel.reflist(
																'assets'
															)[0].asAsset.url
														}
														alt={feature.asModel.str(
															'layertitle'
														)}
													/>
												)}
											</div>
											{category.usedUids &&
												category.usedUids.includes(
													feature.uid
												) && (
													<p className='p2 bold'>
														{i18next.t(
															'UsedFeature'
														)}
													</p>
												)}

											<p className='p1 short'>
												{feature.asModel.str(
													'layertitle'
												)}
											</p>
										</div>
									</>
								))}
							</>
						))}
					</HeightLimitCarousel>
				</div>
			</div>
		</NotifyScrollable>
	);
}
