import './styles.scss';
import SSPContentVO from 'sspcontentext/src/model/SSPContentVO';
import React, { useContext, useEffect, useRef, useState } from 'react';
import { BackToSetup, SspProviderContext } from '../../did-utility';
import BuildInfo from '../../did-utility/src/Components/BuildInfo';
import i18next from 'i18next';
import LanguageMap from '../../did-utility/src/Components/LaungugeMap';
import { checkHtml } from '../../did-utility/src/Functions/checkHTML';
import { marked } from 'marked';
import { __MARKED_OPTIONS } from '../Constants';

export default function Footer({
	showLargeFooter,
}: {
	showLargeFooter: boolean;
}) {
	const [languageOpen, setLanguageOpen] = useState<boolean>(false);
	const [showXlFooter, setShowXlFooter] = useState<boolean>(false);
	const store = useContext(SspProviderContext);
	const footerRef = useRef<HTMLDivElement>(null);
	const contentModel = store.state.content
		? store.state.content.asModel
		: new SSPContentVO('').asModel;
	const languages = Object.keys(i18next.options.resources as Object);
	const individualFooter =
		(contentModel.target.individual_footer &&
			contentModel.target.individual_footer[i18next.language]) ||
		contentModel.str('individual_footer');

	const footerText = checkHtml(
		marked.parse(individualFooter, __MARKED_OPTIONS) as string
	);

	useEffect(() => {
		if (footerRef.current) {
			if (showLargeFooter && footerText.__html) {
				footerRef.current?.classList.add('l-footer');
			} else {
				footerRef.current?.classList.remove('l-footer');
			}
		}
	}, [showLargeFooter]);

	return (
		<>
			<div ref={footerRef} className='footer'>
				<div className='footer-content'>
					<div className='footer-buttons'>
						{!showLargeFooter && footerText.__html && (
							<button
								className='icon-button'
								id='footer-info-btn'
								onClick={() => {
									setShowXlFooter(prev => !prev);
								}}
							>
								<svg className='icon-24'>
									<use
										xlinkHref={
											showXlFooter
												? '#cancel'
												: '#system-info'
										}
									/>
								</svg>
							</button>
						)}
						<button
							id='footer-language-btn'
							className='set-language-open'
							onClick={() => setLanguageOpen(true)}
							style={
								languages.length > 1
									? {}
									: { width: 0, padding: 0 }
							}
						>
							{languages.length > 1 && (
								<>
									<svg className='icon-24'>
										<use xlinkHref='#language-white' />
									</svg>
									<p
										className={`${
											languageOpen ? 'active ' : ''
										}language-letters`}
									>
										{i18next.t('ThisLanguage', {
											defaultValue: i18next.language,
										})}
									</p>
								</>
							)}
						</button>
					</div>
					{languages.length > 1 && (
						<div
							className={`language-popup${
								languageOpen ? ' open' : ''
							}`}
						>
							<button
								onClick={() => {
									setLanguageOpen(false);
								}}
							>
								<svg className='icon-48 lang'>
									<use xlinkHref='#cancel' />
								</svg>
							</button>
							<div className='languages'>
								<LanguageMap
									languages={languages}
									store={store}
									setLanguageOpen={setLanguageOpen}
								/>
							</div>
						</div>
					)}
					<p className='small'>
						{contentModel.str('vin') && (
							<>
								{i18next.t('SerialNumberAbbreviation') +
									': ' +
									contentModel.str('vin')}
							</>
						)}
						{contentModel.has('vin') &&
						contentModel.has('commissionnr')
							? ' | '
							: ''}
						{contentModel.str('commissionnr') && (
							<>
								{i18next.t('JobNumberAbbreviation') +
									': ' +
									contentModel.str('commissionnr')}
							</>
						)}

						<BuildInfo local={i18next.language} />
					</p>
				</div>
				{(showLargeFooter || showXlFooter) && (
					<div className='scrollable-footer'>
						<p
							className={`individual-footer ${
								showXlFooter ? ' maxi' : 'mini'
							}`}
							dangerouslySetInnerHTML={footerText}
						/>
					</div>
				)}
			</div>
			<BackToSetup
				brand={'audi'}
				langBtnId={'footer-language-btn'}
				infoBtnId={'footer-info-btn'}
			/>
		</>
	);
}
