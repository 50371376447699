import React, { useContext } from 'react';
import SSPContentFieldAccessor from 'sspcontentext/src/model/accessor/SSPContentFieldAccessor';
import {
	consumptionList,
	ConsumptionListType,
	getConsumptionValue,
} from '../../did-utility/src/Functions/consumptionLists';
import i18next from 'i18next';
import SSPContentVO from 'sspcontentext/src/model/SSPContentVO';
import { getNumberString, SspProviderContext } from '../../did-utility';
import { getSetup } from '../../did-utility/src/Functions/getSetup';

export function UpperTableElement(props: {
	headline: string;
	h1?: number | string;
	h1p2?: number | string;
	h1p3?: number | string;
	h1p4?: number | string;
	h3?: number | string;
	unit?: string;
	unitp2?: string;
	unitp3?: string;
	unitp4?: string;
	disclaimer?: number | undefined;
	svg?: string | number;
}) {
	const store = useContext(SspProviderContext);
	const contentModel = store.state.content
		? store.state.content.asModel
		: new SSPContentVO('').asModel;
	const engine = contentModel.ref('engine').asModel;
	const KWButNoPS =
		(engine.str('fuel_power2_PS') === '' &&
			engine.str('fuel_power2') !== '') ||
		(engine.str('fuel_power_PS') === '' &&
			engine.str('fuel_power') !== '') ||
		(engine.str('max_output_ps') === '' &&
			engine.str('max_output_kw') !== '');

	return (
		<div className='upper-table-element'>
			<p className='short bold'>
				{i18next.t(props.headline)}
				{props.disclaimer && props.disclaimer !== -1 && (
					<sup>{props.disclaimer}</sup>
				)}
			</p>
			<span>
				{props.h1 && (
					<h1>
						{props.h1}{' '}
						{props.unit && (
							<span className='short bold'>
								{' ' + props.unit}
							</span>
						)}
					</h1>
				)}
				{props.h1p2 && (
					<h1
						className={
							KWButNoPS &&
							engine.str('engine_type') !== 'HybridPetrol'
								? 'hidden'
								: ''
						}
					>
						{props.h1p2}{' '}
						{props.unitp2 && (
							<span className='short bold'>
								{' ' + props.unitp2}
								<br />
							</span>
						)}
					</h1>
				)}
				{props.h1p3 && (
					<h1>
						{props.h1p3}{' '}
						{props.unitp3 && (
							<span className='short bold'>
								{' ' + props.unitp3}
							</span>
						)}
					</h1>
				)}
				{props.h1p4 && (
					<h1>
						{props.h1p4}{' '}
						{props.unitp4 && (
							<span className='short bold'>
								{' ' + props.unitp4}
							</span>
						)}
					</h1>
				)}
				{props.h3 && (
					<h3>
						{props.h3}
						{props.unit && (
							<span className='short bold'>
								{' ' + props.unit}
							</span>
						)}
					</h3>
				)}
			</span>
			{props.svg && (
				<svg className='critair'>
					{props.svg === 1 ? (
						<use xlinkHref='#critAir1' />
					) : props.svg === 2 ? (
						<use xlinkHref='#critAir2' />
					) : props.svg === 'bev' ? (
						<use xlinkHref='#critAirBEV' />
					) : (
						<use />
					)}
				</svg>
			)}
		</div>
	);
}

export function UpperTableElementConsumption(props: {
	headline: string;
	value: string;
	unit: string;
	model: SSPContentFieldAccessor;
	engine?: SSPContentFieldAccessor;
	add?: any;
	disclaimer?: number | undefined;
}) {
	const engineType = props.engine
		? (props.engine.str('engine_type') as keyof ConsumptionListType)
		: undefined;
	const list =
		engineType && consumptionList.wltp
			? consumptionList.wltp[engineType]
			: undefined;
	const setup = getSetup();

	function h1(key: string) {
		const consumption = list?.find(c => c.key === key);
		if (!consumption) return <></>;
		return (
			<h1>
				{getConsumptionValue({ ...consumption, unit: '' }, props.model)}{' '}
				<span className='short bold'>
					{`${i18next.t(consumption?.unit)}`}
					{props.disclaimer && <sup>{props.disclaimer}</sup>}
				</span>
			</h1>
		);
	}

	return (
		<div className='upper-table-element'>
			<p className='short bold'>{i18next.t(props.headline)}</p>
			{setup.market.name !== 'MEX' &&
				h1(props.value.replace('electric_', ''))}
			{h1(props.value)}
			{props.add}
		</div>
	);
}

export function UpperTableElementNedcWltp(props: {
	headline: string;
	value: string;
	unit: string;
	model: SSPContentFieldAccessor;
	engine?: SSPContentFieldAccessor;
	add?: any;
	disclaimer?: number | undefined;
}) {
	const wltp = 'wltp';
	//nedc deprecated
	// !props.model.has(props.value.replace('xxx', 'wltp')) &&
	// props.model.has(props.value.replace('xxx', 'nedc'))
	// 	? 'nedc'
	// 	: 'wltp';

	if (props.value.includes('consumption') || props.value.includes('CO2')) {
		return (
			<UpperTableElementConsumption
				{...props}
				headline={`${i18next.t(props.headline)}`}
				value={props.value.replace('xxx', wltp)}
			/>
		);
	}

	return (
		<UpperTableElement
			{...props}
			headline={`${i18next.t(props.headline)}`}
			h1={props.model.float(props.value.replace('xxx', wltp), NaN)}
		/>
	);
}

export function UpperTableElementNumber(props: {
	headline: string;
	value: number;
	unit: string;
	add?: any;
	disclaimer?: number | undefined;
}) {
	return (
		<UpperTableElement
			{...props}
			h1={getNumberString(props.value, i18next.language)}
			unit={i18next.t(props.unit)}
		/>
	);
}
